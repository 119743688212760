import Vue from "vue";
import Vuex from "vuex";
import firebase from "../../firebaseConfig";
const db = firebase.firestore();

Vue.use(Vuex);
// const presentation = 'HPa6GubXzBlvbr0PX4ap'

export default new Vuex.Store({
  state: {
    presenter: {},
    activeSlide: 0,
    activeChapter: 0,
    importedChapters: [],
    importedSlides: [],
    presentationData: null,
    browsingActive: false,
    endScreenActive: false,
    presentationStarted: false,
    openSubslide: null,
    presentationId: null,
    voting: null,
    clickMarker: {
      X: null,
      Y: null,
    },
  },
  mutations: {
    nextSlide(state) {
      if (state.activeSlide < state.importedChapters[state.activeChapter].slides.length - 1) {
        state.activeSlide += 1;
      } else if ((state.activeSlide == state.importedChapters[state.activeChapter].slides.length - 1) && state.activeChapter < state.importedChapters.length - 1) {
        state.activeChapter += 1;
        state.activeSlide = 0;
      }
    },
    previousSlide(state) {
      if (state.activeSlide > 0) {
        state.activeSlide -= 1;
      } else if (state.activeSlide == 0 && state.activeChapter > 0) {
        state.activeChapter -= 1;
        state.activeSlide = state.importedChapters[state.activeChapter].slides.length - 1;
      }
    },
    importFromFirestore(state, payload) {
      state.importedChapters = payload;
    },
    getActiveSlideFromFirestore(state, payload) {
      state.activeSlide = payload;
    },
    getActiveChapterFromFirestore(state, payload) {
      state.activeChapter = payload;
    },
    startPresentation(state, payload) {
      state.presentationStarted = payload;
    },
    activateFreeBrowsing(state) {
      state.browsingActive = true;
    },
    gotoSlide(state, payload) {
      state.activeSlide = payload.slide;
      state.activeChapter = payload.chapter;
    },
    setBrowsingState(state, payload) {
      state.browsingActive = payload;
    },
    setEndScreenState(state, payload) {
      state.endScreenActive = payload;
    },
    setOpenSubslide(state, payload) {
      state.openSubslide = payload;
    },
    setClickMarker(state, payload) {
      state.clickMarker = payload;
    },
    setPresentationId(state, payload) {
      state.presentationId = payload;
    },
    setVotingActive(state, payload) {
      state.voting = payload;
    },
    setPresenterData(state, payload) {
      state.presenter = payload;
    }
  },
  actions: {
    NEXTSLIDE({ commit }) {
      commit("nextSlide");
    },
    PREVIOUSSLIDE({ commit }) {
      commit("previousSlide");
    },
    GOTOSLIDE({ commit }, payload) {
      commit("gotoSlide", payload);
    },
    GETDATAFROMFIREBASE({ commit }, id) {
      let chaptersData = [];
      var userId = "";
      var voting = null;
      var presRef = db.collection("presentations").doc(id);
      presRef.get().then((doc) => {
        doc.data().chapters.forEach((chapter) => {
          chaptersData.push({ ...chapter });
        });
        userId = doc.data().user;
        voting = doc.data().voting;
        db.collection("users").doc(userId).get().then((user) => {
          commit("setPresenterData", user.data())
        })
        commit("setVotingActive", voting)
      });
      
      commit("setPresentationId", id)
      commit("importFromFirestore", chaptersData);
      let activeSlide = 0;
      let presentationStarted = false;
      let browsingActive = false;
      let endScreenActive = false;
      let openSubslide = null;
      let clickMarker = null;
      let activeChapter = null;
      db.collection("presentations")
        .doc(id)
        .onSnapshot((snapshot) => {
          activeSlide = snapshot.data().activeSlide;
          activeChapter = snapshot.data().activeChapter;
          presentationStarted = snapshot.data().presentationStarted;
          browsingActive = snapshot.data().browsingActive;
          endScreenActive = snapshot.data().endScreenActive;
          openSubslide = snapshot.data().openSubslide;
          clickMarker = snapshot.data().clickMarker;
          commit("setBrowsingState", browsingActive);
          commit("setEndScreenState", endScreenActive);
          commit("getActiveSlideFromFirestore", activeSlide);
          commit("getActiveChapterFromFirestore", activeChapter);
          commit("startPresentation", presentationStarted);
          commit("setOpenSubslide", openSubslide);
          commit("setClickMarker", clickMarker);
        });
    },
  },
  modules: {},
});
