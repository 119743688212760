var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$router.currentRoute.name == 'Feedback')?_c('div',[_c('router-view')],1):_c('div',{attrs:{"id":"app"}},[_c('div',{attrs:{"id":"navbar"}},[(
        this.$store.state.presentationStarted &&
        this.$router.currentRoute.name == 'Viewslide'
      )?_c('div',[_c('span',{attrs:{"id":"title"}},[_c('strong',[_vm._v(_vm._s(_vm.chapterTitle))]),_vm._v(" - "+_vm._s(_vm.titleForNavbar))]),_c('span',{attrs:{"id":"description"}},[_vm._v(_vm._s(_vm.descriptionForNavbar))])]):(
        this.$store.state.presentationStarted &&
        this.$router.currentRoute.name == 'IndexPage'
      )?_c('div',[_vm._m(0)]):(
        this.$store.state.presentationStarted &&
        this.$router.currentRoute.name == 'Kontakt'
      )?_c('div',[_vm._m(1)]):_c('div',[_c('span',{attrs:{"id":"title"}},[_vm._v("Presentationen börjar snart...")])]),_c('div',{staticClass:"navbar__buttons"},[_c('button',{attrs:{"disabled":!this.$store.state.browsingActive || !this.$store.state.presentationStarted},on:{"click":function($event){_vm.$router.push('/indexpage').catch(function () {})}}},[_c('img',{attrs:{"src":require("./assets/index.svg"),"alt":""}}),_vm._v("Index ")]),_c('button',{attrs:{"disabled":!this.$store.state.browsingActive || !this.$store.state.presentationStarted},on:{"click":function($event){_vm.$router.push('/kontakt').catch(function () {})}}},[_c('img',{attrs:{"src":require("./assets/contact.svg"),"alt":""}}),_vm._v("Kontakt ")])])]),_c('router-view'),_vm._m(2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{attrs:{"id":"title"}},[_c('strong',[_vm._v("Index")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{attrs:{"id":"title"}},[_c('strong',[_vm._v("Kontakt")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"footer"}},[_c('img',{attrs:{"id":"footer__logos","src":require("./assets/loggor.png"),"alt":"Logotypes"}})])}]

export { render, staticRenderFns }