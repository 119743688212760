import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDHouTznyUVqYKEJXeeNNwbl23WvzNHbYI",
  authDomain: "novartis-presentation.firebaseapp.com",
  databaseURL: "https://novartis-presentation-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "novartis-presentation",
  storageBucket: "novartis-presentation.appspot.com",
  messagingSenderId: "584396286505",
  appId: "1:584396286505:web:37634f80e7f9333eab0a38"
};

export default firebase.initializeApp(firebaseConfig);