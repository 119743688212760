<template>
  <div class="start__container">
    <h1>Välkommen</h1>
    <div class="voting" v-if="votingActive">
      <p>
        Innan vi börjar skulle vi gärna vilja veta vilka områden som intresserar
        dig mest.
      </p>
      <draggable
        v-model="sortChapters"
        group="kapitel"
        @start="drag = true"
        @end="drag = false"
        draggable=".draggable__kapitel"
        ghost-class="draggable__ghost"
        class="draggable__container"
        handle=".handle"
      >
        <div
          v-for="element in chapters"
          :key="element.id"
          class="draggable__kapitel"
        >
          <span class="handle"> &#9776;</span> {{ element.chapterTitle }}
        </div>
      </draggable>
      <p>
        Dra och släpp ämnen i den rangordning de intresserar dig, från mest
        intressant till minst intressant, så anpassar vi presentationen efter dina
        val.
      </p>
    </div>
    <div class="welcome" v-else>
      <p>
        Det här kommer vi att prata om idag.
      </p>
      <ul>
        <li v-for="chapter in chapters" :key="chapter.id">
          {{ chapter.chapterTitle }}
        </li>
      </ul>
    </div>
    <router-link v-if="idIfSet" :to="{ name: 'Viewslide' }" class="btn"
      >&#10004; {{ votingActive ? 'Färdig' : 'Fortsätt' }}</router-link
    >
    <!-- <button v-if="idIfSet" @click="handleStart" class="btn">
      &#10004; Färdig 2
    </button> -->
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
	name: 'Start',
  data: function () {
    return {
      sortChapters: [],
    };
  },
  props: ["id"],
  components: {
    draggable,
  },
  computed: {
    idIfSet() {
      if (this.id) {
        return this.id;
      } else {
        return false;
      }
    },
    votingActive() {
      return this.$store.state.voting;
    },
    chapters() {
      return this.$store.state.importedChapters;
    },
  },
  // methods: {
  //   handleStart() {
  //     if (this.id) {
  //       this.$store.dispatch("POSTSORTING", this.kapitel.join(";"));
  //     }
  //   },
  // },
  mounted() {
    if (this.id) {
      this.$store.dispatch("GETDATAFROMFIREBASE", this.id);
    }
    this.chapters.forEach((chapter) => {
      this.sortChapters.push(chapter.chapterTitle)
    })
  },
};
</script>

<style lang="scss">
.start__container {
  width: 40rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
    text-align: left;
  }
}
.draggable__kapitel {
  display: block;
  padding: 0 1rem 0 0;
  margin: 0.5rem 0;
  border: var(--border);
  user-select: none;
  background-color: white;
  .handle {
    display: inline-block;
    cursor: move;
    padding: 0.3rem 0.5rem;
    margin-right: 0.5rem;
    color: white;
    background-color: #222;
  }
}
.draggable__container {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.draggable__ghost {
  opacity: 0.2;
}
.welcome ul li {
  text-align: left;
}
</style>