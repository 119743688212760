<template>
  <div v-if="this.$router.currentRoute.name == 'Feedback'">
    <router-view />
  </div>
  <div v-else id="app">
    <div id="navbar">
      <div
        v-if="
          this.$store.state.presentationStarted &&
          this.$router.currentRoute.name == 'Viewslide'
        "
      >
        <span id="title"
          ><strong>{{ chapterTitle }}</strong> - {{ titleForNavbar }}</span
        >
        <span id="description">{{ descriptionForNavbar }}</span>
      </div>
      <div
        v-else-if="
          this.$store.state.presentationStarted &&
          this.$router.currentRoute.name == 'IndexPage'
        "
      >
        <span id="title"><strong>Index</strong></span>
      </div>
      <div
        v-else-if="
          this.$store.state.presentationStarted &&
          this.$router.currentRoute.name == 'Kontakt'
        "
      >
        <span id="title"><strong>Kontakt</strong></span>
      </div>
      <div v-else>
        <span id="title">Presentationen börjar snart...</span>
      </div>
      <div class="navbar__buttons">
        <!-- <button @click="toggleArrows">Surfa fritt</button> -->
        <button
          :disabled="!this.$store.state.browsingActive || !this.$store.state.presentationStarted"
          @click="$router.push('/indexpage').catch(() => {})"
        >
          <img src="./assets/index.svg" alt="" />Index
        </button>
        <button
          :disabled="!this.$store.state.browsingActive || !this.$store.state.presentationStarted"
          @click="$router.push('/kontakt').catch(() => {})"
        >
          <img src="./assets/contact.svg" alt="" />Kontakt
        </button>
      </div>
    </div>
    <router-view />
    <div id="footer">
      <img id="footer__logos" src="./assets/loggor.png" alt="Logotypes" />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'TafMek Presentation',
    htmlAttrs: {
      lang: 'sv'
    }
  },
  data: function () {
    return {};
  },
  computed: {
    titleForNavbar() {
      if (this.activeSlide < this.chapters[this.activeChapter].slides.length) {
        return this.chapters[this.activeChapter].slides[this.activeSlide].title;
      } else {
        return "";
      }
    },
    chapterTitle() {
      return this.chapters[this.activeChapter].chapterTitle;
    },
    descriptionForNavbar() {
      if (this.activeSlide < this.chapters[this.activeChapter].slides.length) {
        return this.chapters[this.activeChapter].slides[this.activeSlide]
          .description;
      } else {
        return "";
      }
    },
    chapters() {
      return this.$store.state.importedChapters;
    },
    activeChapter() {
      return this.$store.state.activeChapter;
    },
    activeSlide() {
      return this.$store.state.activeSlide;
    },
  },
};
</script>

<style lang="scss">
:root {
  --navbarheight: 3rem;
  --footerheight: 50px;
  --border: 2px solid var(--black);
  --black: #111111;
  --brand: hsl(197, 100%, 41%);
  --branddark: hsl(224, 44%, 43%);
  --navbar: hsl(223, 17%, 33%);
}
div {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--black);
  display: grid;
  grid-template-rows: var(--navbarheight) auto var(--footerheight);
  width: 100vw;
  height: 100vh;
  position: relative;
}
#navbar {
  padding: 0 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--navbarheight);
  background-color: var(--navbar);
  color: white;
  flex: 0 0 var(--navbarheight);
  button {
    color: white;
    cursor: pointer;
    font-size: 12px;
    padding: 0.4rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: unset;
    border: none;
    border-radius: 0.3rem;
    box-sizing: border-box;
    transition: background-color 200ms ease-in;
    &:is(:disabled, :disabled:active) {
      color: white;
      opacity: 0.3;
      cursor: unset;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      transition: background-color 100ms ease-out;
    }
    &:is(:disabled, :disabled:active):hover {
      background-color: unset;
    }
    &:last-child {
      margin-right: 0;
    }
    img {
      display: inline-block;
      height: 12px;
      margin-right: 0.3rem;
    }
  }
}
.navbar__buttons {
  display: flex;
}
#title {
  display: block;
  text-align: left;
  font-weight: 700;
  font-size: 1rem;
}
#description {
  display: block;
  text-align: left;
  font-size: 0.8rem;
}
.btn {
  display: flex;
  align-items: center;
  border: var(--border);
  border-color: var(--branddark);
  border-radius: 0.2rem;
  padding: 0.4rem 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  text-decoration: none;
  transition: background-color 500ms ease-in;
  background-color: var(--brand);
  position: relative;
  cursor: pointer;
  &:hover {
    transition: background-color 100ms ease-out;
    background-color: var(--branddark);
  }
}
ol {
  padding-left: 2rem;
  li {
    margin-bottom: 0.5rem;
    padding-bottom: 2px;
    border-bottom: 2px solid rgba(0,0,0,0);
    color: #777777;
    transition: all 300ms ease-in-out;
    &::marker {
      font-weight: 700;
      color: #777777;
      transition: color 300ms ease-in-out;
    }
    &.selected {
      border-bottom: 2px solid black;
      color: black;
      &::marker {
        color: black;
      }
    }
  }
}
.navbutton {
  text-decoration: none;
  color: black;
}
#footer {
  width: auto;
  flex: 0 1 80px;
  display: flex;
  padding: 10px 0;
  background-color: white;
  img {
    transition: margin 300ms ease-in-out;
    height: 100%;
    display: block;
  }
}
a {
  color: var(--black);
}
</style>
