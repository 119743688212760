import Vue from "vue";
import VueRouter from "vue-router";
import Start from "../views/Start.vue";
import Blank from "../views/Blank.vue";
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta)

const routes = [
  {
    path: "/",
    name: "Blank",
    component: Blank,
  },
  {
    path: "/presentation/:id",
    name: "Presentation",
    component: Start,
    props: true,
  },
  {
    path: "/viewslide/:id",
    name: "Viewslide",
    props: true,
    component: () =>
      import(/* webpackChunkName: "viewSlide" */ "../views/Viewslide.vue"),
  },
  {
    path: "/indexpage",
    name: "IndexPage",
    component: () =>
      import(/* webpackChunkName: "indexPage" */ "../views/IndexPage.vue"),
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: () =>
      import(/* webpackChunkName: "kontakt" */ "../views/Kontakt.vue"),
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () =>
      import(/* webpackChunkName: "kontakt" */ "../views/Feedback.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
