<template>
  <div class="start__container">
    <h1>Välkommen</h1>
    <div class="welcome">
      <p>Du behöver använda en länk skickad från din kontaktperson för att kunna delta.</p>
      <p>Be din kontaktperson skicka länken och försök igen.</p>
      <ul></ul>
    </div>
  </div>
</template>

<script>
export default {
	name: 'Blank'
}
</script>